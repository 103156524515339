
import { Add, Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";
import BasicDateRangePicker from "../../quotation/ui/BasicDateRangePicker.quotation";

const FilterPurchase = ({ onSearchData, onOpenAddModal, onLoadData }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchName: "",
      dateRange: [],
    },
  });

  return (
    <form onSubmit={handleSubmit(onSearchData)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"text"}
                title={"Buscar por código..."}
              />
            )}
          />
        </div>
        <div className="col-span-6 md:col-span-3 flex gap-2">
          <Controller
            name="dateRange"
            className="!w-full"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BasicDateRangePicker value={value} onChange={onChange} />
            )}
          />
          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
            disabled={onLoadData}
          >
            <Search />
          </IconButton>
        </div>
      </div>
      <div className="col-span-12 md:col-span-4 flex justify-end mb-4">
        <Button onClick={() => onOpenAddModal(true)} className="button-default">
          Crear nuevo
          <Add />
        </Button>
      </div>
    </form>
  );
};

export default FilterPurchase;
