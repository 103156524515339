import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import InputDefault from "../../atomic/atoms/input.atoms";
import { config } from "../../config";

import { s3Client } from "../../libs/s3Client";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import dayjs from "dayjs";
import { addFeePayment } from "../../services/planning.services";

const TableFeePayment = ({ selected }) => {
  return (
    <Table
      aria-label="sticky table"
      size={"small"}
      stickyHeader
      className="!border-gray-200"
    >
      <TableHead>
        <TableRow>
          <TableCell
            align={"left"}
            style={{ width: 100 }}
            className="!font-semibold !text-xs !text-gray-800"
          >
            Fecha de pago
          </TableCell>
          <TableCell
            align={"left"}
            style={{ width: 100 }}
            className="!font-semibold !text-xs !text-gray-800"
          >
            Valor
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className="!text-center">
          <TableCell>{selected?.date_feepayment}</TableCell>
          <TableCell>{selected?.value_feepayment}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const DialogFeePayment = ({ open, setOpen, selected, callback }) => {
  const initialValues = {
    idFeepayment: null,
    filenameFeepayment: null,
    valuepayFeepayment: 0,
    paymentdateFeepayment: dayjs().format("YYYY-MM-DD HH:mm"),
    inputFileFeepayment: null,
    observationsFeepayment: "",
  };

  const [feePayment, setFeePayment] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handleUpload = async (inputFile) => {
    if (!inputFile) {
      toast.error("Debe de subir el contrato de cotización");

      return;
    }

    const file = inputFile[0];
    const fileExtension = file.name.split(".").pop();
    const fileName = `${uuidv4()}.${fileExtension}`;

    const bucketParams = {
      Bucket: "ubuntu-storage",
      Key: `planning/finance/${fileName}`,
      Body: file,
      ACL: "public-read",
    };

    try {
      await s3Client.send(new PutObjectCommand(bucketParams));
      return fileName;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      let fileName;

      // check if file is empty
      if (
        feePayment?.inputFileFeepayment &&
        feePayment?.inputFileFeepayment !== ""
      ) {
        fileName = await handleUpload(feePayment.inputFileFeepayment);
      }

      const response = await addFeePayment({
        ...feePayment,
        filenameFeepayment: fileName,
        idFeepayment: selected.id_feepayment,
      });

      setLoading(false);

      setFeePayment({
        ...feePayment,
        filenameFeepayment: response[0]?.filename_feepayment || "",
      });
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setOpen(null);
      setFeePayment(initialValues);
    }

    setFeePayment({
      ...feePayment,
      idFeepayment: selected?.id_feepayment,
      filenameFeepayment: selected?.filename_feepayment || "",
      valuepayFeepayment: selected?.valuepay_feepayment || 0,
      paymentdateFeepayment: selected?.paymentdate_feepayment
        ? dayjs(selected?.paymentdate_feepayment).format("YYYY-MM-DD HH:mm")
        : dayjs().format("YYYY-MM-DD HH:mm"),
      observationsFeepayment: selected?.observations_feepayment || "",
    });
  }, [open]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(null)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-select-sucursal"
    >
      <form autoComplete="off">
        <DialogTitle>
          Registrar pago
          <IconButton
            aria-label="close"
            onClick={() => setOpen(null)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TableFeePayment selected={selected} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputDefault
                disabled={loading}
                title="Valor de pago"
                name="valuepayFeepayment"
                type={"number"}
                value={feePayment.valuepayFeepayment}
                onChange={(e) =>
                  setFeePayment({
                    ...feePayment,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputDefault
                disabled={loading}
                title="Fecha de pago"
                name="paymentdateFeepayment"
                type={"datetime-local"}
                value={feePayment.paymentdateFeepayment}
                onChange={(e) =>
                  setFeePayment({
                    ...feePayment,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              {feePayment.filenameFeepayment && (
                <img
                  src={`${config.doUrlSpace}/planning/finance/${feePayment.filenameFeepayment}`}
                  alt="filename"
                  className="w-80 h-auto text-center m-auto mb-4"
                />
              )}

              <input
                name="inputFileFeepayment"
                type={"file"}
                accept="image/*"
                onChange={(e) =>
                  setFeePayment({
                    ...feePayment,
                    [e.target.name]: e.target.files,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputDefault
                disabled={loading}
                title="Detalles"
                name="observationsFeepayment"
                type={"textarea"}
                minRows={4}
                value={feePayment.observationsFeepayment}
                onChange={(e) =>
                  setFeePayment({
                    ...feePayment,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="text" onClick={() => setOpen(null)}>
            Salir
          </Button>
          <Button
            disabled={loading}
            color="secondary"
            variant="contained"
            type="button"
            onClick={() => handleSubmit()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogFeePayment;
