import CustomTable from "../../../atomic/organisms/Table.organisms";
import ContainerInput from "../../../components/ContainerInput";

const CheckInventory = ({ products }) => {
  const columns = [
    {
      id: "prod_id",
      label: "Código",
      minWidth: 80,
      align: "left",
    },
    {
      id: "prod_nombre",
      label: "Producto",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "name_medida",
      label: "Medida",
      minWidth: 60,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "prod_stock",
      label: "Stock",
      minWidth: 60,
      align: "left",
    },
    {
      id: "value_product_by_event_total",
      label: "Cantidad",
      minWidth: 60,
      align: "left",
    },
    {
      id: "tota_buy",
      label: "Total a comprar",
      minWidth: 60,
      align: "left",
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-12">
        <ContainerInput className="p-0">
          <CustomTable
            columns={columns}
            rows={products}
            // selectedRows={selectedPlanning}
            load={false}
            error={false}
            pagination={true}
          />
        </ContainerInput>
      </div>
    </div>
  );
};

export default CheckInventory;
