import InputDefault from "../../../atomic/atoms/input.atoms";
import ContainerInput from "../../../components/ContainerInput";

const UpdateInformation = ({ selectedInfo, setSelectedInfo }) => {
  const onChange = (e) => {
    setSelectedInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <ContainerInput className="p-4">
      <div className="grid grid-cols-12 gap-4 w-full">
        <div className="col-span-12 md:col-span-12">
          <p className="text-sm font-semibold">Datos de la compra</p>
        </div>
        <div className="col-span-12 md:col-span-3">
          <InputDefault
            required
            loading={false}
            fullWidth
            value={selectedInfo?.priority}
            onChange={onChange}
            title={"Prioridad"}
            name="priority"
            type={"select"}
            options={[
              { value: "Alta", label: "Alta" },
              { value: "Media", label: "Media" },
              { value: "Baja", label: "Baja" },
            ]}
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <InputDefault
            required
            loading={false}
            fullWidth
            value={selectedInfo?.purchaseDate}
            onChange={onChange}
            name="purchaseDate"
            title={"Fecha de compra"}
            type={"date"}
          />
        </div>
        <div className="col-span-12 md:col-span-7">
          <InputDefault
            required
            loading={false}
            fullWidth
            value={selectedInfo?.description}
            onChange={onChange}
            type={"textarea"}
            title={"Comentarios / observaciones"}
            name="description"
            rows="4"
            cols="50"
          />
        </div>
      </div>
    </ContainerInput>
  );
};

export default UpdateInformation;
