import { Print, Search } from "@mui/icons-material";
import { Button, Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../atomic/atoms/input.atoms";
import BasicDateRangePicker from "../quotation/ui/BasicDateRangePicker.quotation";
import { useAppContext } from "./state.clientHistory";

import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";
import { getUser } from "../../services/auth.services";
import { get_group } from "../../services/reservas.services";

const HistorySearch = () => {
  const state = useAppContext();

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      clasification: "",
      dateRange: [],
      group: "",
      pograma: "",
    },
  });

  const [dataGroup, setDataGroup] = useState([]);

  const NAME_COMPANY = getUser()?.empresa?.alias_empsede;
  const NOW_DATE = new Date().toISOString().slice(0, 10);

  const dataProgramas =
    getUser()?.empresa?.id_emp === 4
      ? [
          {
            value: "cumpleanos",
            label: "Cumpleaños",
          },
          {
            value: "aniversario",
            label: "Aniversario",
          },
          {
            value: "recreacion",
            label: "Recreación",
          },
          {
            value: "Otros",
            label: "Otros",
          },
        ]
      : [
          {
            value: "cumpleanos",
            label: "Cumpleaños",
          },
          {
            value: "aniversario",
            label: "Aniversario",
          },
          {
            value: "recreacion",
            label: "Recreación",
          },
          {
            value: "Otros",
            label: "Otros",
          },
        ];

  const DATE =
    watch("dateRange") && watch("dateRange").length > 0
      ? `(${watch("dateRange")[0]}/${watch("dateRange")[1]})`
      : `(${NOW_DATE})`;

  const handlePrint = () => {
    const data = state?.dataExcel.length
      ? state.dataExcel.map((el) => ({
          Nombres: el?.fullname_cliente.trim() || "",
          Rut: el?.rut_cliente || "",
          Teléfono: el?.telf_cliente || "",
          "Correo electrónico": el?.correo_cliente || "",
          Clasificación: el?.clasificacion_cliente || "",
          "Última visita": dayjs(el.last_visite).format("DD/MM/YYYY HH:mm:ss"),
          "Total visitas": el?.tot_history || 0,
        }))
      : [];

    let wb = utils.book_new();
    let ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, `Reporte`);
    writeFile(wb, `${NAME_COMPANY || "REPORT"}-${DATE}.xlsx`);
  };

  useEffect(() => {
    const handleFetchGroup = async () => {
      try {
        const response = await get_group();
        setDataGroup(
          response.map((el) => ({
            label: el.name_rservagrupa,
            value: el.id_rservagrupa,
          }))
        );
      } catch (err) {}
    };

    // SOLO PARA EL CLIENTE 4 (DOÑA ANITA)
    getUser()?.empresa.id_emp === 4 && handleFetchGroup();
  }, []);

  return (
    <form onSubmit={handleSubmit(state.search)} autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                value={value}
                onChange={onChange}
                type="text"
                label="Nombre de cliente"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="clasification"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Clasificación"}
                options={[
                  { value: "Nuevo", label: "Nuevo" },
                  { value: "Frecuente", label: "Frecuente" },
                  { value: "Ocasional", label: "Ocasional" },
                  { value: "Perdido", label: "Perdido" },
                  { value: "Recuperado", label: "Recuperado" },
                  { value: "Sin clasificación", label: "Sin clasificación" },
                ]}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="pograma"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Programa"}
                options={dataProgramas}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="group"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                loading={false}
                fullWidth
                value={value}
                onChange={onChange}
                type="select"
                title={"Segmentación"}
                options={dataGroup}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} className="flex justify-end gap-2">
          <Controller
            name="dateRange"
            className="!w-full"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BasicDateRangePicker value={value} onChange={onChange} />
            )}
          />

          <IconButton
            type="submit"
            size="medium"
            color="primary"
            style={{ background: "#eee", marginRight: "10px" }}
          >
            <Search />
          </IconButton>
        </Grid>
        {/* <Grid item xs={3}>
          <Controller
            name="date_start"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                required // This is the important part
                value={value}
                onChange={onChange}
                type="date"
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="date_end"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                fullWidth
                required // This is the important part
                value={value}
                onChange={onChange}
                type="date"
              />
            )}
          />
        </Grid> */}
      </Grid>

      <br />

      <Grid container spacing={1} className="flex justify-end gap-2 mt">
        <Grid item xs={2} style={{ textAlign: "right" }}>
          <Button
            size="medium"
            color="primary"
            style={{ background: "#eee" }}
            onClick={handlePrint}
          >
            Imprimir <Print />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default HistorySearch;
