import { Alert } from "@mui/material";
import SummaryPurchase from "./summary.purchase";

const FinalPurchase = ({
  selectedInfo,
  selectedEvent,
  selectedProducts,
  purchaseData,
}) => {
  const { purchaseCode } = purchaseData;

  return (
    <div className="w-full">
      <div className="text-center">
        <h1 className="text-2xl font-bold text-purple-700">
          ¡Felicitaciones se acaba de crear correctamente la Orden de Compra!
        </h1>
        <h3 className="text-xl font-semibold mt-2">Nº {purchaseCode}</h3>
      </div>
      <Alert severity="info" className="mb-4 mt-4">
        <p className="text-sm">
          Por favor, no olvidar finalizar la compra para culminar el proceso.
        </p>
      </Alert>
      <SummaryPurchase
        selectedInfo={selectedInfo}
        selectedEvent={selectedEvent}
        selectedProducts={selectedProducts}
        isFinalComponent={true}
      />
    </div>
  );
};

export default FinalPurchase;
