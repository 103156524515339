import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";

const StepperPurchase = (props) => {
  const { steps, finalStep, onCloseAction, onActiveStep } = props;

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = async () => {
    const newStep = activeStep + 1;
    const response = await onActiveStep(newStep);
    if (!response) return;

    setActiveStep(newStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className="w-[90%] mx-auto"
      >
        {steps.map((data, index) => {
          const { title } = data;
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={title} {...stepProps}>
              <StepLabel {...labelProps}>{title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <div className="py-8">{finalStep}</div>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseAction}
            >
              Salir
            </Button>
          </Box>
        </>
      ) : (
        <>
          <div className="py-8">{steps[activeStep]?.component}</div>
          <div className="w-[100%] mx-auto">
            <Box className="inline-flex end-steps float-end">
              <Button
                variant="contained"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Atrás
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
              </Button>
            </Box>
          </div>
        </>
      )}
    </Box>
  );
};

export default StepperPurchase;
