import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_user = () => {
  const url = `${baseurl}/api/user/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_user_today = () => {
  const url = `${baseurl}/api/user/get_today/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_user = (params) => {
  const url = `${baseurl}/api/user/add/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const search_user = (params) => {
  const url = `${baseurl}/api/user/search/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_user = (params, id) => {
  const url = `${baseurl}/api/user/update/${id}/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_user = (id) => {
  const url = `${baseurl}/api/user/delete/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_user_by_id = (id) => {
  const url = `${baseurl}/api/user/get/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_typeuser = () => {
  const url = `${baseurl}/api/user/get_typeuser/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

// UTILES
export const get_comunas = () => {
  const url = `${baseurl}/api/user/get_comunas/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_bancos = () => {
  const url = `${baseurl}/api/user/get_bancos/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_metaMonth_seller = (params) => {
  const url = `${baseurl}/api/user/get_metamonth_seller/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_metaMonth_seller = (params) => {
  const url = `${baseurl}/api/user/update_metamonth_seller/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_user_sellers = (params) => {
  const url = `${baseurl}/api/user/get_user_sellers/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
