import { Save } from "@mui/icons-material";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ContainerInput from "../../components/ContainerInput";
import {
  addPlaningCeremony,
  getPlaningCeremony,
} from "../../services/planning.services";
import DynamicTable from "./ui/dynamicTable";

const MenuComponent = ({ title, children, ...props }) => {
  return (
    <>
      <p
        className={`text-sm font-semibold mb-2 bg-slate-400 p-2 text-white ${props.className}`}
      >
        {title}
      </p>
      <ContainerInput className="p-4">{children}</ContainerInput>
    </>
  );
};

const LoadingComponent = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <CircularProgress style={{ color: "#e0004d", marginBottom: 5 }} />
      <div style={{ fontSize: "18px", color: "#e0004d" }}>Cargando...</div>
    </div>
  );
};

const Ceremony = ({ data, isReadMode, canCheck = false }) => {
  const idPlanning = data.id_planning;

  const [dataCeremony, setDataCeremony] = useState({
    idPlanning,
    ceremonyDetail: [],
  });

  const [load, setLoad] = useState(false);

  let columns = [
    {
      id: "delete",
      label: "Eliminar",
      input: "delete",
      minWidth: 10,
      align: "left",
    },
    {
      id: "eventtypeCeremony",
      label: "Tipo de evento",
      minWidth: 200,
      align: "left",
      input: "select",
      options: [
        { value: "ceremonia", label: "Ceremonia" },
        { value: "recepcion", label: "Recepción" },
        { vañue: "cena", label: "Cena" },
        { value: "fiesta", label: "Fiesta" },
      ],
    },
    {
      id: "itemCeremony",
      label: "Item del evento",
      minWidth: 400,
      align: "left",
      input: "text",
    },
    {
      id: "timeCeremony",
      label: "Hora",
      minWidth: 100,
      align: "left",
      input: "time",
    },
    {
      id: "musiclinkCeremony",
      label: "Link musical",
      minWidth: 400,
      align: "left",
      input: "text",
    },
    {
      id: "observationsCeremony",
      label: "Observación tradicional",
      minWidth: 500,
      align: "left",
      input: "text",
    },
    {
      id: "specialdetailsCeremony",
      label: "Detalles especiales",
      minWidth: 500,
      align: "left",
      input: "text",
    },
  ];

  if (canCheck) {
    columns.push({
      id: "checkCeremony",
      label: "Comprobar",
      minWidth: 10,
      input: "check",
      align: "left",
    });
  }

  const handleMapData = (data) => {
    let response = data;

    response = response.map((el) => ({
      itemCeremony: el?.item_ceremony,
      timeCeremony: el?.time_ceremony,
      musiclinkCeremony: el?.musiclink_ceremony,
      observationsCeremony: el?.observations_ceremony,
      specialdetailsCeremony: el?.specialdetails_ceremony,
      eventtypeCeremony: el?.eventtype_ceremony,
      checkCeremony: el?.check_ceremony === 1 ? true : false,
    }));

    setDataCeremony({
      ...dataCeremony,
      ceremonyDetail: response,
    });
  };

  const handleFetchData = async () => {
    setLoad(true);

    try {
      let response = await getPlaningCeremony(idPlanning);
      setLoad(false);

      handleMapData(response);
    } catch (err) {
      setLoad(false);
    }
  };

  const handleOnSubmit = async (data) => {
    setLoad(true);

    try {
      let response = await addPlaningCeremony(data);

      handleMapData(response);

      setLoad(false);
    } catch (err) {
      setLoad(false);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  if (load) {
    return <LoadingComponent />;
  }

  return (
    <MenuComponent title="Ceremonia">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <DynamicTable
            isReadMode={isReadMode}
            columns={columns}
            rows={dataCeremony.ceremonyDetail}
            setRows={($rows) =>
              setDataCeremony({ ...dataCeremony, ceremonyDetail: $rows })
            }
            addRow={true}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {canCheck && (
            <Button
              onClick={() => handleOnSubmit(dataCeremony)}
              className="text-right"
              variant="contained"
              size="small"
              color="secondary"
              type="button"
            >
              <Save /> Guardar
            </Button>
          )}

          {!isReadMode && (
            <Button
              onClick={() => handleOnSubmit(dataCeremony)}
              className="text-right"
              variant="contained"
              size="small"
              color="secondary"
              type="button"
            >
              <Save /> Guardar
            </Button>
          )}
        </Grid>
      </Grid>
    </MenuComponent>
  );
};

export default Ceremony;
