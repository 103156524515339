import { FileCopy, PointOfSale } from "@mui/icons-material";
import { Link as StyledLink } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { config } from "../../config";
import { getPlanning } from "../../services/planning.services";
import { getUser } from "../../services/auth.services";

const PlanningPage = () => {
  const [dataPlanning, setDataPlanning] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  const ENCARGADO_FINANZAS_ID = 20;

  let columns = [
    {
      id: "filecontract_quotation",
      label: "Contrato",
      minWidth: 50,
      align: "center",
      format: (item) => {
        if (!item)
          return (
            <div className={`text-gray-400 cursor-auto`}>
              <FileCopy />
            </div>
          );

        const url = `${config.doUrlSpace}/quotation/${item}`;

        return (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={`text-blue-500`}
          >
            <FileCopy />
          </a>
        );
      },
    },
    {
      id: "cod_planning",
      label: "Código",
      minWidth: 80,
      align: "left",
      format: (el) => {
        return (
          <Link to={`/planning/${el}`}>{<StyledLink>{el}</StyledLink>}</Link>
        );
      },
    },
    {
      id: "name_event",
      label: "Evento",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "name_typeevent",
      label: "Tipo de evento",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "usu_nombre",
      label: "Usuario",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "created_at_planning",
      label: "Fecha creación",
      minWidth: 160,
      align: "left",
      format: (el) => {
        return dayjs(el).utc().format("DD/MM/YYYY");
      },
    },
  ];

  if (getUser()?.id_perfil === ENCARGADO_FINANZAS_ID) {
    columns.push({
      id: "cod_planning",
      label: "Finanzas",
      minWidth: 50,
      align: "center",
      format: (item) => {
        return (
          <Link
            to={`/planning/finance/${item}`}
            rel="noopener noreferrer"
            className={`text-blue-500`}
          >
            <PointOfSale />
          </Link>
        );
      },
    });
  }

  const fetchDataPlanning = async () => {
    try {
      setLoad(true);
      const data = await getPlanning();

      setLoad(false);

      setDataPlanning(data);
    } catch (error) {
      console.error("Error en fetchDataPlanning: ", error);
    }
  };

  useEffect(() => {
    fetchDataPlanning();
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          {/* <h2 className="mb-4">
            <span className="text-2xl font-bold">{dataPlanning.length}</span>{" "}
          </h2> */}
          <ContainerInput className="p-0">
            <CustomTable
              columns={columns}
              rows={dataPlanning}
              load={load}
              error={false}
              pagination={true}
            />
          </ContainerInput>
        </div>
      </div>
    </Template>
  );
};

export default PlanningPage;
