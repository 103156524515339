import axios from "axios";
import { config } from "../config";
import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

/**
 * CATEGORIA
 */

export const get_categoria = () => {
  const url = `${baseurl}/api/categoria/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_categoria = (params) => {
  const url = `${baseurl}/api/categoria/add/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_categoria = (params) => {
  const url = `${baseurl}/api/categoria/update/`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_categoria = (id) => {
  const url = `${baseurl}/api/categoria/delete/${id}/`;

  return axios
    .delete(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

/**
 * SUB CATEGORIA
 */

export const get_subcategoria = () => {
  const url = `${baseurl}/api/categoria/subcategoria/get/`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
