import React, { useEffect, useState } from "react";

// import ContainerInput from "../../components/ContainerInput";

import { getUser } from "../../services/auth.services";

import { OpenInNew } from "@mui/icons-material";
import { Checkbox, Grid, IconButton } from "@mui/material";

import "./style.menu.scss";

import useEmpresa from "../../hooks/useEmpresa";

import Categoria from "./categoria/categoria";
import Producto from "./producto/producto";

import InputDefault from "../../atomic/atoms/input.atoms";
import { getUrlFront } from "../../functions/utilidades.function";

const MenuMain = ({ valueTab }) => {
  const dataEmpresa = useEmpresa(getUser().empresa.id_empsede, "id");
  const [checked, setChecked] = useState(false);

  const [linkCarta, setLinkCarta] = useState(null);

  const url = getUrlFront();

  const handleChangeFreeLink = () => {
    if (!checked) {
      setLinkCarta(`${url}/carta/${dataEmpresa.data.alias_empsede}?user=free`);
    } else {
      setLinkCarta(`${url}/carta/${dataEmpresa.data.alias_empsede}`);
    }

    setChecked(!checked);
  };

  useEffect(() => {
    setLinkCarta(`${url}/carta/${dataEmpresa.data.alias_empsede}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEmpresa]);

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <div style={{ display: "inline-flex", width: "55vh" }}>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              Libre
              <Checkbox
                value={checked}
                onChange={() => handleChangeFreeLink()}
              />
            </div>
            {dataEmpresa.load === false ? (
              <>
                <InputDefault
                  title="Link carta"
                  type="text"
                  value={linkCarta}
                  readOnly
                />
                <IconButton
                  style={{ marginLeft: 5 }}
                  color="warning"
                  onClick={() => window.open(linkCarta, "_blank")}
                >
                  <OpenInNew />
                </IconButton>
              </>
            ) : (
              false
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={4}>
          <Categoria />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Producto />
        </Grid>
      </Grid>
    </>
  );
};

export default MenuMain;
