import { useEffect, useState } from "react";

import { Save } from "@mui/icons-material";
import { Button, CircularProgress, Grid } from "@mui/material";
import InputDefault from "../../atomic/atoms/input.atoms";
import ContainerInput from "../../components/ContainerInput";
import DynamicTable from "./ui/dynamicTable";
import {
  addPlaningMenu,
  getPlaningMenu,
} from "../../services/planning.services";
import toast from "react-hot-toast";
import { get_plato } from "../../services/plato.services";

const MenuComponent = ({ title, children, ...props }) => {
  return (
    <>
      <p
        className={`text-sm font-semibold mb-2 bg-slate-400 p-2 text-white ${props.className}`}
      >
        {title}
      </p>
      <ContainerInput className="p-4">{children}</ContainerInput>
    </>
  );
};

const LoadingComponent = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <CircularProgress style={{ color: "#e0004d", marginBottom: 5 }} />
      <div style={{ fontSize: "18px", color: "#e0004d" }}>Cargando...</div>
    </div>
  );
};

const CoctelMenu = ({
  onSubmit,
  data,
  setData,
  loading,
  isReadMode,
  dataPlatos,
}) => {
  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <MenuComponent title="Coctel">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Piezas por persona"
            name="qtyPiezas"
            type="number"
            value={data.qtyPiezas}
            onChange={(e) =>
              setData({
                ...data,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Bebestible por persona"
            name="qtyBebestible"
            type="number"
            value={data.qtyBebestible}
            onChange={(e) =>
              setData({
                ...data,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DynamicTable
            isReadMode={isReadMode}
            columns={[
              {
                id: "delete",
                label: "Eliminar",
                input: "delete",
                minWidth: 10,
                align: "left",
              },
              {
                id: "id_plato",
                label: "Nombre",
                minWidth: 400,
                align: "left",
                input: "autocomplete",
                autoCompleteOptions: dataPlatos?.data || [],
              },
              {
                id: "qty_menudetail",
                label: "Cantidad",
                minWidth: 400,
                align: "left",
                input: "number",
              },
            ]}
            rows={data.menuDetail}
            setRows={($rows) => setData({ ...data, menuDetail: $rows })}
            addRow={true}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputDefault
            isReadMode={isReadMode}
            title="Observaciones"
            name="observations"
            type={"textarea"}
            minRows={2}
            value={data.observations}
            onChange={(e) =>
              setData({
                ...data,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        {!isReadMode && (
          <Grid item xs={12} sm={12}>
            <Button
              onClick={() => onSubmit(data)}
              className="text-right"
              variant="contained"
              size="small"
              color="secondary"
              type="button"
            >
              <Save /> Guardar
            </Button>
          </Grid>
        )}
      </Grid>
    </MenuComponent>
  );
};

const CenaMenu = ({ onSubmit, data, setData, loading, isReadMode, dataPlatos }) => {
  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <MenuComponent title="Cena" className="mt-4">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <DynamicTable
            isReadMode={isReadMode}
            columns={[
              {
                id: "delete",
                label: "Eliminar",
                input: "delete",
                minWidth: 10,
                align: "left",
              },
              {
                id: "sector_menudetail",
                label: "Tipo de sector",
                minWidth: 300,
                align: "left",
                input: "select",
                options: [
                  { value: "adulto", label: "Adulto" },
                  { value: "niño", label: "Niño" },
                  { vañue: "colacion", label: "Colación" },
                  { value: "vegetariano", label: "Vegetariano" },
                  { value: "especial", label: "Especial" },
                  { value: "vino", label: "Vino" },
                ],
              },
              {
                id: "type_menudetail",
                label: "Tipo de menú",
                minWidth: 300,
                align: "left",
                input: "select",
                options: [
                  { value: "entrada", label: "Entrada" },
                  { value: "plato_fondo", label: "Plato fondo" },
                  { value: "postre", label: "Postre" },
                ],
              },
              {
                id: "id_plato",
                label: "Nombre",
                minWidth: 400,
                align: "left",
                input: "autocomplete",
                autoCompleteOptions: dataPlatos?.data || [],
              },
              {
                id: "qty_menudetail",
                label: "Cantidad",
                minWidth: 150,
                align: "left",
                input: "number",
              },
            ]}
            rows={data.menuDetail}
            setRows={($rows) => setData({ ...data, menuDetail: $rows })}
            addRow={true}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputDefault
            isReadMode={isReadMode}
            title="Observaciones"
            name="observations"
            type={"textarea"}
            minRows={2}
            value={data.observations}
            onChange={(e) =>
              setData({
                ...data,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        {!isReadMode && (
          <Grid item xs={12} sm={12}>
            <Button
              onClick={() => onSubmit(data)}
              className="text-right"
              variant="contained"
              color="secondary"
              type="button"
              size="small"
            >
              <Save /> Guardar
            </Button>
          </Grid>
        )}
      </Grid>
    </MenuComponent>
  );
};

const BarraMenu = ({ onSubmit, data, setData, loading, isReadMode, dataPlatos }) => {
  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <MenuComponent title="Barra" className="mt-4">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <DynamicTable
              isReadMode={isReadMode}
              columns={[
                {
                  id: "delete",
                  label: "Eliminar",
                  input: "delete",
                  minWidth: 10,
                  align: "left",
                },
                {
                  id: "sector_menudetail",
                  label: "Tipo de sector",
                  minWidth: 400,
                  align: "left",
                  input: "select",
                  options: [
                    { value: "bar_estandar", label: "BAR ESTÁNDAR" },
                    { value: "bar_intermedio", label: "BAR INTERMEDIO" },
                    { value: "bar_especial_agua", label: "BAR ESPECIAL AGUA" },
                  ],
                },
                {
                  id: "id_plato",
                  label: "Nombre",
                  minWidth: 400,
                  align: "left",
                  input: "autocomplete",
                  autoCompleteOptions: dataPlatos?.data || [],
                },
              ]}
              rows={data.menuDetail}
              setRows={($rows) => setData({ ...data, menuDetail: $rows })}
              addRow={true}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputDefault
              isReadMode={isReadMode}
              title="Observaciones"
              name="observations"
              type={"textarea"}
              minRows={2}
              value={data.observations}
              onChange={(e) =>
                setData({
                  ...data,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </Grid>
          {!isReadMode && (
            <Grid item xs={12} sm={12}>
              <Button
                onClick={() => onSubmit(data)}
                className="text-right"
                variant="contained"
                color="secondary"
                type="button"
                size="small"
              >
                <Save /> Guardar
              </Button>
            </Grid>
          )}
        </Grid>
      </MenuComponent>
    </>
  );
};

const EventMenu = ({ data, isReadMode }) => {
  const [load, setLoad] = useState({
    coctel: false,
    cena: false,
    barra: false,
  });

  const [dataPlatos, setDataPlatos] = useState({
    data: [],
    load: false,
  });

  const idPlanning = data.id_planning;

  const initialValues = {
    idPlMenu: null,
    idPlanning,
    qtyPiezas: 0,
    qtyBebestible: 0,
    observations: "",
    menuDetail: [],
    type: null,
  };

  const [dataMenu, setDataMenu] = useState({
    coctel: initialValues,
    cena: initialValues,
    barra: initialValues,
  });

  const handleSubmit = async (type) => {
    const data = { ...dataMenu[type], type };

    if (type === "coctel") {
      if (Number(data?.qtyPiezas) === 0 || Number(data?.qtyBebestible) === 0) {
        toast.error("Debe ingresar cantidad de piezas y bebestible");
        return;
      }
    }

    if (data?.menuDetail.length === 0) {
      toast.error("Debe agregar platos");
      return;
    }

    try {
      setLoad((prev) => ({ ...prev, [type]: true }));
      setDataPlatos({ ...dataPlatos, load: true });
      await addPlaningMenu(data);

      setLoad((prev) => ({ ...prev, [type]: false }));
      setDataPlatos({ ...dataPlatos, load: false });
    } catch (err) {
      setLoad((prev) => ({ ...prev, [type]: false }));
    }
  };

  const handleFetchData = async (type) => {
    if (!type) return;

    setLoad((prev) => ({ ...prev, [type]: true }));

    try {
      let response = await getPlaningMenu(idPlanning, type);

      // set load
      setLoad((prev) => ({ ...prev, [type]: false }));

      // first element selected
      response = response[0];

      // format data
      response = {
        idPlMenu: response.id_plmenu,
        qtyPiezas: response.qtyperperson_plmenu,
        qtyBebestible: response.qtybebestileperperson_plmenu,
        observations: response.observations_plmenu,
        menuDetail: response.menuDetail.map((el) => ({
          qty_menudetail: el.qty_plmdetail,
          name_menudetail: el.name_plmdetail,
          sector_menudetail: el.typearea_plmdetail,
          type_menudetail: el.typemenu_plmdetail,
          id_plato: el.id_plato,
        })),
        type,
      };

      // set data
      setDataMenu((prev) => ({ ...prev, [type]: response }));
    } catch (err) {
      setLoad({
        ...load,
        [type]: false,
      });
    }
  };

  const handleFetchAllData = async () => {
    await handleFetchData("coctel");
    await handleFetchData("cena");
    await handleFetchData("barra");
  };

  const handleFetchPlatos = async () => {
    try {
      setDataPlatos({ load: true, data: [], err: false });
      let response = await get_plato();

      response = response.map((el) => ({
        label: el.nombre_plato,
        value: el.id_plato,
      }));

      setDataPlatos({ load: false, data: response, err: false });
    } catch (err) {
      setDataPlatos({
        load: false,
        data: [],
        err: true,
      });
    }
  };

  useEffect(() => {
    handleFetchAllData();
    handleFetchPlatos();
  }, []);

  return (
    <>
      <CoctelMenu
        onSubmit={() => handleSubmit("coctel")}
        data={dataMenu.coctel}
        setData={(data) => setDataMenu({ ...dataMenu, coctel: data })}
        loading={load.coctel && dataPlatos.load}
        isReadMode={isReadMode}
        dataPlatos={dataPlatos}
      />
      <CenaMenu
        onSubmit={() => handleSubmit("cena")}
        data={dataMenu.cena}
        setData={(data) => setDataMenu({ ...dataMenu, cena: data })}
        loading={load.cena}
        isReadMode={isReadMode}
        dataPlatos={dataPlatos}
      />
      <BarraMenu
        onSubmit={() => handleSubmit("barra")}
        data={dataMenu.barra}
        setData={(data) => setDataMenu({ ...dataMenu, barra: data })}
        loading={load.barra}
        isReadMode={isReadMode}
        dataPlatos={dataPlatos}
      />
    </>
  );
};

export default EventMenu;
