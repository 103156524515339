/* eslint-disable eqeqeq */
import React, { useEffect, useReducer, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@mui/material";

import {
  AccountCircle,
  Assessment,
  AttachMoney,
  Ballot,
  Business,
  Class,
  Description,
  Fastfood,
  Flag,
  History,
  HistoryToggleOff,
  Home,
  Inventory,
  Kitchen,
  ListAlt,
  LocalAtm,
  LocalPrintshop,
  Loyalty,
  MenuBook,
  Money,
  Password,
  People,
  PeopleAlt,
  ProductionQuantityLimits,
  RoomService,
  SettingsAccessibility,
  ShoppingBasket,
  Store,
  TableBar,
  TableChart,
  Task,
  Texture,
  TurnLeftRounded,
} from "@mui/icons-material";

import { getUser } from "../services/auth.services";

const initialState = {
  expandMantMenu: false,
  expandInsumos: false,
  user: {
    id: 0,
    name: "",
    username: "",
  },
  mobile: false,
};

const LinkApp = (props) => {
  const { to, title, active, icon } = props;
  return (
    <Link to={to}>
      <ListItem
        className={`!px-4 !py-1 rounded-r-lg  ${
          active && "!bg-purple-800 !text-white"
        }`}
      >
        <ListItemText>
          <span className="text-xs">{title}</span>
        </ListItemText>
        <ListItemSecondaryAction
          className={`${active && "text-white"} !text-xs`}
        >
          {icon}
        </ListItemSecondaryAction>
      </ListItem>
    </Link>
  );
};

const initialWordUser = (user) => {
  const letrasUserName = user ? (user.name ? user.name : null) : null;

  let initLetras = "";

  if (letrasUserName) {
    if (letrasUserName.split(" ").length === 2) {
      if (letrasUserName.split(" ")[0][0] && letrasUserName.split(" ")[1][0]) {
        initLetras =
          letrasUserName.split(" ")[0][0] + letrasUserName.split(" ")[1][0];
      } else {
        initLetras = letrasUserName.split(" ")[0][0];
      }
    } else if (letrasUserName.split(" ").length === 1) {
      initLetras = letrasUserName.split(" ")[0][0];
    }
  } else {
    initLetras = "AD";
  }

  return initLetras;
};

const LinksApp = (splitLocation) => {
  const EXTERNAL_SELLER = 14;

  /*
    ROOT
  */

  if (getUser().id_perfil === 16) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/consumption"
          title="Consumos"
          active={splitLocation[1] === "consumption"}
          icon={<Fastfood />}
        />
        <LinkApp
          to="/orderHistory"
          title="Historial de caja"
          active={splitLocation[1] === "orderHistory"}
          icon={<MenuBook />}
        />
        <LinkApp
          to="/clientHistory"
          title="Clientes"
          active={splitLocation[1] === "clientHistory"}
          icon={<History />}
        />
        <LinkApp
          to="/menu"
          title="Menú"
          active={splitLocation[1] === "menu"}
          icon={<Texture />}
        />
        <LinkApp
          to="/reserva"
          title="Reservas"
          active={splitLocation[1] === "reserva"}
          icon={<ListAlt />}
        />
        <LinkApp
          to="/orders"
          title="Pedidos"
          active={splitLocation[1] === "orders"}
          icon={<RoomService />}
        />
        <LinkApp
          to="/tasks"
          title="Agendas"
          active={splitLocation[1] === "tasks"}
          icon={<Task />}
        />
        <LinkApp
          to="/checker"
          title="Caja"
          active={splitLocation[1] === "checker"}
          icon={<LocalAtm />}
        />
        <LinkApp
          to="/checkerSecondary"
          title="Caja secundaria"
          active={splitLocation[1] === "checkerSecondary"}
          icon={<Description />}
        />
        <LinkApp
          to="/closebox"
          title="Cierre de caja"
          active={splitLocation[1] === "closebox"}
          icon={<RoomService />}
        />
        <LinkApp
          to="/platosSoldOut"
          title="Capacidad de platos"
          active={splitLocation[1] === "platosSoldOut"}
          icon={<ProductionQuantityLimits />}
        />
        <LinkApp
          to="/menu_mant/mesas"
          title="Mesas"
          active={splitLocation[2] === "mesas"}
          icon={<TableChart />}
        />
        <LinkApp
          to="/assignTable"
          title="Asignar mesa"
          active={splitLocation[1] === "assignTable"}
          icon={<TableBar />}
        />
        <LinkApp
          to="/cookorders"
          title="Cocina"
          active={splitLocation[1] === "cookorders"}
          icon={<RoomService />}
        />
        <LinkApp
          to="/cookordersHistory"
          title="Historial de cocina"
          active={splitLocation[1] === "cookordersHistory"}
          icon={<RoomService />}
        />
        {(getUser()?.empresa.id_emp === 7 ||
          getUser()?.empresa.id_emp === 4) && (
          <LinkApp
            to="/quotation"
            title="Cotización"
            active={splitLocation[1] === "quotation"}
            icon={<Class />}
          />
        )}
        <LinkApp
          to="/planning"
          title="Planificación"
          active={splitLocation[1] === "planning"}
          icon={<Flag />}
        />
        <LinkApp
          to="/garzonesActive"
          title="Garzones"
          active={splitLocation[1] === "garzonesActive"}
          icon={<SettingsAccessibility />}
        />
        <LinkApp
          to="/products"
          title="Productos"
          active={splitLocation[1] === "products"}
          icon={<Kitchen />}
        />
        <LinkApp
          to="/supplier"
          title="Proveedores"
          active={splitLocation[1] === "supplier"}
          icon={<PeopleAlt />}
        />
        <LinkApp
          to="/purchase"
          title="Compras"
          active={splitLocation[1] === "purchase"}
          icon={<ShoppingBasket />}
        />
      </>
    );
  }

  if (getUser().id_perfil === 1) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/orders"
          title="Pedidos"
          active={splitLocation[1] === "orders"}
          icon={<RoomService />}
        />
        <LinkApp
          to="/completeOrders"
          title="Historial"
          active={splitLocation[1] === "completeOrders"}
          icon={<HistoryToggleOff />}
        />
      </>
    );
  }

  /*
    COCINERO | BARTENDER | PASTELERO
  */

  if (
    getUser().id_perfil === 2 ||
    getUser().id_perfil === 3 ||
    getUser().id_perfil === 10
  ) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/cookorders"
          title="Pedidos"
          active={splitLocation[1] === "cookorders"}
          icon={<RoomService />}
        />
        <LinkApp
          to="/cookordersHistory"
          title="Pedidos historial"
          active={splitLocation[1] === "cookordersHistory"}
          icon={<Kitchen />}
        />
        <LinkApp
          to="/consumption"
          title="Consumos"
          active={splitLocation[1] === "consumption"}
          icon={<Fastfood />}
        />
        <LinkApp
          to="/delivery-products"
          title="Productos ordenados"
          active={splitLocation[1] === "delivery-products"}
          icon={<Ballot />}
        />
        <LinkApp
          to="/reserva"
          title="Reservas"
          active={splitLocation[1] === "reserva"}
          icon={<ListAlt />}
        />
      </>
    );
  }

  /*
    VENDEDOR INTERNO DE GALA DH
  */

  if (getUser().id_perfil === 6 && getUser().empresa.id_empsede === 9) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/quotation"
          title="Cotización"
          active={splitLocation[1] === "quotation"}
          icon={<Class />}
        />
        <LinkApp
          to="/tasks"
          title="Agendas"
          active={splitLocation[1] === "tasks"}
          icon={<Task />}
        />
        {/* <LinkApp
            to="/historialClientes"
            title="Historial clientes"
            active={splitLocation[1] === "historialClientes"}
            icon={<Group />}
          /> */}
      </>
    );
  }

  /*
    VENDEDOR EXTERNO
  */

  if (getUser().id_perfil === EXTERNAL_SELLER) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/reserva"
          title="Reservas"
          active={splitLocation[1] === "reserva"}
          icon={<ListAlt />}
        />
        <LinkApp
          to="/wallet"
          title="Mi Billetera"
          active={splitLocation[1] === "wallet"}
          icon={<Money />}
        />
        <LinkApp
          to="/wallet-history"
          title="Historial de pagos"
          active={splitLocation[1] === "wallet-history"}
          icon={<History />}
        />
      </>
    );
  }

  /*
    VENDEDOR INTERNO
  */

  if (getUser().id_perfil === 6) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/reserva"
          title="Reservas"
          active={splitLocation[1] === "reserva"}
          icon={<ListAlt />}
        />
        {(getUser()?.empresa.id_emp === 7 ||
          getUser()?.empresa.id_emp === 4) && (
          <LinkApp
            to="/quotation"
            title="Cotización"
            active={splitLocation[1] === "quotation"}
            icon={<Class />}
          />
        )}
        <LinkApp
          to="/tasks"
          title="Agendas"
          active={splitLocation[1] === "tasks"}
          icon={<Task />}
        />
        <LinkApp
          to="/menu"
          title="Menú"
          active={splitLocation[1] === "menu"}
          icon={<Texture />}
        />
      </>
    );
  }

  /**
   * RRHH
   */

  if (getUser().id_perfil === 15) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/pay-merchante"
          title="Cuentas merchantes"
          active={splitLocation[1] === "pay-merchante"}
          icon={<Assessment className="icon-nav" />}
        />
      </>
    );
  }

  /*
    ANFITRION
  */

  if (getUser().id_perfil === 7) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/reserva"
          title="Reservas"
          active={splitLocation[1] === "reserva"}
          icon={<ListAlt />}
        />
      </>
    );
  }

  /*
    CAJERO
  */

  if (getUser().id_perfil === 8) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/checker"
          title="Caja"
          active={splitLocation[1] === "checker"}
          icon={<LocalAtm />}
        />
        <LinkApp
          to="/checkerSecondary"
          title="Caja secundaria"
          active={splitLocation[1] === "checkerSecondary"}
          icon={<Description />}
        />
        <LinkApp
          to="/closebox"
          title="Cierre de caja"
          active={splitLocation[1] === "closebox"}
          icon={<RoomService />}
        />
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ fontWeight: 600, color: "#f0b248" }}
        >
          Para hoy
        </ListSubheader>
        <LinkApp
          to="/garzonesActive"
          title="Garzones"
          active={splitLocation[1] === "garzonesActive"}
          icon={<SettingsAccessibility />}
        />
        <LinkApp
          to="/platosSoldOut"
          title="Platos"
          active={splitLocation[1] === "platosSoldOut"}
          icon={<ProductionQuantityLimits />}
        />
        <LinkApp
          to="/assignTable"
          title="Asignar mesa"
          active={splitLocation[1] === "assignTable"}
          icon={<TableBar />}
        />
        <LinkApp
          to="/consumption"
          title="Consumos"
          active={splitLocation[1] === "consumption"}
          icon={<Fastfood />}
        />
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ fontWeight: 600, color: "#f0b248" }}
        >
          Mantenimiento
        </ListSubheader>
        <LinkApp
          to="/menu_mant/mesas"
          title="Mesas"
          active={splitLocation[2] === "mesas"}
          icon={<TableChart />}
        />
        {/* 
          <LinkApp
            to="/soldout"
            title="Productos agotados"
            active={splitLocation[1] === "soldout"}
            icon={<ProductionQuantityLimits />}
          /> 
          */}
      </>
    );
  }

  /*
    COMANDERO
  */

  if (getUser().id_perfil === 9) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/printComandas"
          title="Imprimir comandas"
          active={splitLocation[1] === "printComandas"}
          icon={<LocalPrintshop />}
        />
      </>
    );
  }

  /**
   * PROMOTOR
   */

  if (getUser().id_perfil === 11) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/myTickets"
          title="Tickets"
          active={splitLocation[1] === "myTickets"}
          icon={<Loyalty />}
        />
      </>
    );
  }

  /**
   * BODEGUERO
   */

  if (getUser().id_perfil === 12) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/consumption"
          title="Consumos"
          active={splitLocation[1] === "consumption"}
          icon={<Fastfood />}
        />
        <LinkApp
          to="/delivery-products"
          title="Productos ordenados"
          active={splitLocation[1] === "delivery-products"}
          icon={<Fastfood />}
        />
      </>
    );
  }

  /**
   * JEFE DE SALON
   */

  if (getUser().id_perfil === 13) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/reserva"
          title="Reservas"
          active={splitLocation[1] === "reserva"}
          icon={<ListAlt />}
        />
        <LinkApp
          to="/menu_mant/mesas"
          title="Mesas"
          active={splitLocation[2] === "mesas"}
          icon={<TableChart />}
        />
        <LinkApp
          to="/checker"
          title="Caja"
          active={splitLocation[1] === "checker"}
          icon={<LocalAtm />}
        />
        <LinkApp
          to="/checkerSecondary"
          title="Caja secundaria"
          active={splitLocation[1] === "checkerSecondary"}
          icon={<Description />}
        />
        <LinkApp
          to="/closebox"
          title="Cierre de caja"
          active={splitLocation[1] === "closebox"}
          icon={<RoomService />}
        />
        <LinkApp
          to="/consumption"
          title="Consumos"
          active={splitLocation[1] === "consumption"}
          icon={<Fastfood />}
        />
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ fontWeight: 600, color: "#f0b248" }}
        >
          Para hoy
        </ListSubheader>
        <LinkApp
          to="/garzonesActive"
          title="Garzones"
          active={splitLocation[1] === "garzonesActive"}
          icon={<SettingsAccessibility />}
        />
        <LinkApp
          to="/platosSoldOut"
          title="Platos"
          active={splitLocation[1] === "platosSoldOut"}
          icon={<ProductionQuantityLimits />}
        />
        <LinkApp
          to="/assignTable"
          title="Asignar mesa"
          active={splitLocation[1] === "assignTable"}
          icon={<TableBar />}
        />
        <LinkApp
          to="/cookorders"
          title="Pedidos"
          active={splitLocation[1] === "cookorders"}
          icon={<RoomService />}
        />
        <LinkApp
          to="/cookordersHistory"
          title="Pedidos historial"
          active={splitLocation[1] === "cookordersHistory"}
          icon={<RoomService />}
        />
      </>
    );
  }

  /**
   * PLANIFICADOR
   */

  if (getUser().id_perfil === 18) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/quotation"
          title="Cotización"
          active={splitLocation[1] === "quotation"}
          icon={<Class />}
        />
        <LinkApp
          to="/planning"
          title="Planificación"
          active={splitLocation[1] === "planning"}
          icon={<Flag />}
        />
      </>
    );
  }

  /**
   * GESTIONADOR DE EVENTOS
   */
  if (getUser().id_perfil === 19) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/planning"
          title="Planificación"
          active={splitLocation[1] === "planning"}
          icon={<Flag />}
        />
      </>
    );
  }

  /**
   * ENCARGADO DE FINAZAS
   */
  if (getUser().id_perfil === 20) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/planning"
          title="Planificación"
          active={splitLocation[1] === "planning"}
          icon={<Flag />}
        />
      </>
    );
  }

  /**
   * ENCARGADO ALMACEN Y COMPRAS
   */
  if (getUser().id_perfil === 23) {
    return (
      <>
        <LinkApp
          to="/home"
          title="Inicio"
          active={splitLocation[1] === "home"}
          icon={<Home className="icon-nav" />}
        />
        <LinkApp
          to="/menu"
          title="Gestión de platos"
          active={splitLocation[1] === "menu"}
          icon={<Texture />}
        />
        {/* <LinkApp
          to="/planning"
          title="Planificación"
          active={splitLocation[1] === "planning"}
          icon={<Flag />}
        /> */}
        <LinkApp
          to="/purchase"
          title="Compras"
          active={splitLocation[1] === "purchase"}
          icon={<ShoppingBasket />}
        />
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ fontWeight: 600, color: "#f0b248" }}
        >
          Mantenimiento
        </ListSubheader>
        <LinkApp
          to="/products"
          title="Productos"
          active={splitLocation[1] === "products"}
          icon={<Kitchen />}
        />
        <LinkApp
          to="/product-category"
          title="Categorías"
          active={splitLocation[1] === "product-category"}
          icon={<Kitchen />}
        />
        <LinkApp
          to="/supplier"
          title="Proveedores"
          active={splitLocation[1] === "supplier"}
          icon={<PeopleAlt />}
        />
      </>
    );
  }

  return (
    <>
      <LinkApp
        to="/home"
        title="Inicio"
        active={splitLocation[1] === "home"}
        icon={<Home className="icon-nav" />}
      />
      <LinkApp
        to="/reserva"
        title="Reservas"
        active={splitLocation[1] === "reserva"}
        icon={<ListAlt />}
      />
      <LinkApp
        to="/tasks"
        title="Agendas"
        active={splitLocation[1] === "tasks"}
        icon={<Task />}
      />
      <LinkApp
        to="/benefit"
        title="Beneficios"
        active={splitLocation[1] === "benefit"}
        icon={<Loyalty />}
      />
      <LinkApp
        to="/menu"
        title="Menú"
        active={splitLocation[1] === "menu"}
        icon={<Texture />}
      />
      <LinkApp
        to="/orders"
        title="Pedidos"
        active={splitLocation[1] === "orders"}
        icon={<RoomService />}
      />
      <LinkApp
        to="/printComandas"
        title="Imprimir comandas"
        active={splitLocation[1] === "printComandas"}
        icon={<LocalPrintshop />}
      />
      <LinkApp
        to="/clientHistory"
        title="Historial de clientes"
        active={splitLocation[1] === "clientHistory"}
        icon={<History />}
      />
      <LinkApp
        to="/orderHistory"
        title="Historial de caja"
        active={splitLocation[1] === "orderHistory"}
        icon={<MenuBook />}
      />
      <LinkApp
        to="/platosSoldOut"
        title="Capacidad de platos"
        active={splitLocation[1] === "platosSoldOut"}
        icon={<ProductionQuantityLimits />}
      />
      <LinkApp
        to="/consumption"
        title="Consumos"
        active={splitLocation[1] === "consumption"}
        icon={<Fastfood />}
      />

      <LinkApp
        to="/menu_mant/mesas"
        title="Mesas"
        active={splitLocation[2] === "mesas"}
        icon={<TableChart />}
      />
    </>
  );
};

const LinksAppSettings = (splitLocation) => {
  const user = getUser();

  const ROOT_ID = 4;

  const canReturnHome =
    user?.empresa?.id_empsede && user?.id_perfil !== ROOT_ID;

  return (
    <>
      {canReturnHome ? (
        <Link to="/home">
          <ListItem
            className={`itemNav ${
              splitLocation[2] === "home" && "!bg-purple-800"
            }`}
          >
            <ListItemText
              primary="Ir a la página de inicio"
              className="!text-white"
            />
            <ListItemSecondaryAction>
              <TurnLeftRounded className="!text-white" />
            </ListItemSecondaryAction>
          </ListItem>
        </Link>
      ) : (
        <Link to="/settings/home">
          <ListItem
            className={`itemNav ${
              splitLocation[2] === "home" && "!bg-purple-800"
            }`}
          >
            <ListItemText primary="Inicio" className="!text-white" />
            <ListItemSecondaryAction>
              <TurnLeftRounded className="!text-white" />
            </ListItemSecondaryAction>
          </ListItem>
        </Link>
      )}

      <Link to="/settings/my-profile">
        <ListItem
          className={`itemNav ${
            splitLocation[2] === "my-profile" && "!bg-purple-800"
          }`}
        >
          <ListItemText primary="Mi perfil" className="!text-white" />
          <ListItemSecondaryAction>
            <AccountCircle className="!text-white" />
          </ListItemSecondaryAction>
        </ListItem>
      </Link>
      <Link to="/settings/change-password">
        <ListItem
          className={`itemNav ${
            splitLocation[2] === "change-password" && "!bg-purple-800"
          }`}
        >
          <ListItemText primary="Cambiar contraseña" className="!text-white" />
          <ListItemSecondaryAction>
            <Password className="!text-white" />
          </ListItemSecondaryAction>
        </ListItem>
      </Link>

      {(user.id_perfil === 4 || user.id_perfil === 16) && (
        <>
          <Link to="/settings/report">
            <ListItem
              className={`itemNav ${
                splitLocation[2] === "report" && "!bg-purple-800"
              }`}
            >
              <ListItemText primary="Reportería" className="!text-white" />
              <ListItemSecondaryAction>
                <Assessment className="!text-white" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          <Link to="/settings/reservation-sellers">
            <ListItem
              className={`itemNav ${
                splitLocation[2] === "reservation-sellers" && "!bg-purple-800"
              }`}
            >
              <ListItemText
                primary="Productivad de ventas"
                className="!text-white"
              />
              <ListItemSecondaryAction>
                <Assessment className="!text-white" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          <Link to="/settings/pax-productivity">
            <ListItem
              className={`itemNav ${
                splitLocation[2] === "pax-productivity" && "!bg-purple-800"
              }`}
            >
              <ListItemText
                primary="Asistencia efectiva"
                className="!text-white"
              />
              <ListItemSecondaryAction>
                <Assessment className="!text-white" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          <Link to="/settings/companies">
            <ListItem
              className={`itemNav ${
                splitLocation[2] === "companies" && "!bg-purple-800"
              }`}
            >
              <ListItemText primary="Empresas" className="!text-white" />
              <ListItemSecondaryAction>
                <Business className="!text-white" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          <Link to="/settings/sucursales">
            <ListItem
              className={`itemNav ${
                splitLocation[2] === "sucursales" && "!bg-purple-800"
              }`}
            >
              <ListItemText primary="Sucursales" className="!text-white" />
              <ListItemSecondaryAction>
                <Store className="!text-white" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          <Link to="/settings/sucursales-colegio">
            <ListItem
              className={`itemNav ${
                splitLocation[2] === "sucursales-colegio" && "!bg-purple-800"
              }`}
            >
              <ListItemText
                primary="Sucursales colegio"
                className="!text-white"
              />
              <ListItemSecondaryAction>
                <Store className="!text-white" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          <Link to="/settings/users">
            <ListItem
              className={`itemNav ${
                splitLocation[2] === "users" && "!bg-purple-800"
              }`}
            >
              <ListItemText primary="Usuarios" className="!text-white" />
              <ListItemSecondaryAction>
                <People className="!text-white" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
          <Link to="/settings/merchant-goal">
            <ListItem
              className={`itemNav ${
                splitLocation[2] === "merchant-goal" && "!bg-purple-800"
              }`}
            >
              <ListItemText primary="Merchantes" className="!text-white" />
              <ListItemSecondaryAction>
                <AttachMoney className="!text-white" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
        </>
      )}
    </>
  );
};

/* APP */

const MenuBar = ({ activeBar, activeBarMobile, setActiveBarMobile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [initLetras, setInitLetras] = useState("");

  let location = useLocation();
  let pathname = location.pathname;

  const onExpand = (typeAction, newValue) =>
    dispatch({ type: actionTypes[typeAction], payload: newValue });

  const onMobile = (newValue) =>
    dispatch({ type: actionTypes.mobile, payload: newValue });

  const onUser = (newValue) =>
    dispatch({ type: actionTypes.userUpdate, payload: newValue });

  const splitLocation = pathname.split("/");

  const isSettingsPanel = pathname.includes("settings");

  useEffect(() => {
    let type = splitLocation[1] === "menu_mant" ? "expandMenu" : null;

    if (type) {
      onExpand(type, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ListItemsMain = () => (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className="!overflow-auto !h-full"
    >
      <div
        style={{ fontWeight: 600, color: "#f0b248" }}
        className="hidden md:!block px-4 mb-2 "
      >
        Principal
      </div>
      <ListItem className="MenuDatosResponsive">
        <div
          className="MenuInfo"
          style={{
            position: "initial",
            boxShadow: "none",
            padding: "0px",
            border: "none",
            background: "transparent",
          }}
        >
          <div className="_HeaderMenu">
            <div className="circle circleName">{initLetras}</div>
            <div className="_NameUser">{state.user.name}</div>
            <div className="_EmailUser">{state.user.username}</div>
          </div>
        </div>
      </ListItem>

      <Divider className="MenuDatosResponsive" />

      {/* LINKS APPS*/}
      {LinksApp(splitLocation)}

      <ListItem
        component="div"
        style={{
          fontWeight: 600,
          fontSize: 12,
          color: "#33058d",
        }}
      >
        © Copyright Ubuntu
      </ListItem>
    </List>
  );

  const ListItemsSettings = () => (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={`ContainerMenu bg-rose-500`}
      style={{
        overflow: "auto",
      }}
    >
      <ListItem component="div" style={{ fontWeight: 600, color: "#f0b248" }}>
        Configuración
      </ListItem>
      <ListItem className="MenuDatosResponsive" style={{ marginTop: -30 }}>
        <div
          className="MenuInfo"
          style={{
            position: "initial",
            boxShadow: "none",
            padding: "0px",
            border: "none",
            background: "transparent",
          }}
        >
          <div className="_HeaderMenu">
            <div className="circle circleName">{initLetras}</div>
            <div className="_NameUser">{state.user.name}</div>
            <div className="_EmailUser">{state.user.username}</div>
          </div>
        </div>
      </ListItem>
      <Divider className="MenuDatosResponsive" />
      {LinksAppSettings(splitLocation)}
      <ListItem
        component="div"
        style={{
          fontWeight: 600,
          fontSize: 12,
          color: "#33058d",
        }}
      >
        © Copyright Ubuntu
      </ListItem>
    </List>
  );

  useEffect(() => {
    if (window.screen.width <= 600) {
      onMobile(true);
    }

    onUser(getUser());
    setInitLetras(initialWordUser(state.user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="MenuBar"
        style={{ display: activeBar == true ? "block" : "none" }}
      >
        {isSettingsPanel ? <ListItemsSettings /> : <ListItemsMain />}
      </div>

      {state.mobile ? (
        <Drawer
          anchor="left"
          open={activeBarMobile}
          onClose={() => setActiveBarMobile(false)}
        >
          <div
            // className={clsx(classes.list)}
            onClick={() => setActiveBarMobile(false)}
            onKeyDown={() => setActiveBarMobile(false)}
            className="!h-full "
          >
            <ListItemsMain />
          </div>
        </Drawer>
      ) : null}
    </>
  );
};

const actionTypes = {
  expandMenu: "EXPAND_MENU",
  expandInsumos: "EXPAND_INSUMOS",
  mobile: "UPDATE_MOBILE",
  userUpdate: "UPDATE_USER",
};

const reducerObject = (state, payload) => ({
  [actionTypes.expandMenu]: {
    ...state,
    expandMantMenu: payload,
  },
  [actionTypes.expandInsumos]: {
    ...state,
    expandInsumos: payload,
  },
  [actionTypes.mobile]: {
    ...state,
    mobile: payload,
  },
  [actionTypes.userUpdate]: {
    ...state,
    user: payload,
  },
});

const reducer = (state, action) => {
  if (reducerObject(state)[action.type]) {
    return reducerObject(state, action.payload)[action.type];
  } else {
    return state;
  }
};

export default MenuBar;
