import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import TableInsumos from "./TableInsumos";

import { useDispatch, useSelector } from "react-redux";

import { actionTypesPlato } from "../../../../../redux/plato/actions";

import { addPlato_producto } from "../../../../../services/productos.services";

import LoadingPage from "../../../../../components/LoadingPage";
import "./style.products.scss";
import { el } from "date-fns/locale";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogProductos = () => {
  const dispatch = useDispatch();

  const { dataSelected, openProducts, paramSelected_product, dataPlato } =
    useSelector((state) => state.reducer_plato);

  const { dataSelected_categoria } = useSelector((state) => state.reducer_menu);

  const [load, setLoad] = React.useState(false);

  const onGetProductos = (data) =>
    dispatch({
      type: actionTypesPlato.getDataPlato,
      payload: data,
    });

  const onOpen = (data) =>
    dispatch({
      type: actionTypesPlato.openModalProducts,
      payload: data,
    });

  const onSetDataSelected = (data) =>
    dispatch({
      type: actionTypesPlato.setDataSelected,
      payload: data,
    });

  const onSetDataSelectedProducts = (data) =>
    dispatch({
      type: actionTypesPlato.setParamSelected_product,
      payload: data,
    });

  const handleModal = (value) => {
    onOpen(value);

    if (!value) {
      onSetDataSelectedProducts([]);
      onSetDataSelected({
        ...dataSelected,
        id_plato: "",
        nombre_plato: "",
        precio_plato: "",
        descripcion_plato: "",
      });
    }
  };

  const handleAddProduct = async () => {
    try {
      setLoad(true);

      onGetProductos({
        ...dataPlato,
        load: true,
        data: [],
        err: false,
      });

      const response = await addPlato_producto({
        id_plato: dataSelected.id_plato,
        platos: paramSelected_product,
      });

      const filter = response.filter(
        (el) => el.id_categoriapla === dataSelected_categoria.id_categoriapla
      );

      onGetProductos({
        ...dataPlato,
        load: false,
        data: filter,
        err: false,
      });

      handleModal(false);
      setLoad(false);
    } catch (err) {
      onGetProductos({
        ...dataPlato,
        load: false,
        data: [],
        err: true,
      });

      handleModal(false);
    }
  };

  React.useEffect(() => {
    if (openProducts) {
      setLoad(false);
      
      onSetDataSelectedProducts(
        dataSelected?.productos.map((el) => ({
          ...el,
          prod_medida_value: el.prod_medida_valor,
        })) || []
      );
    }
  }, [openProducts]);

  return (
    <Dialog
      fullScreen
      open={openProducts}
      onClose={() => handleModal(false)}
      TransitionComponent={Transition}
    >
      <LoadingPage open={load} />
      <AppBar sx={{ position: "relative" }} color="secondary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleModal(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {/* <Typography>Productos</Typography> */}
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            className="uppercase"
          >
            RECETARIO PARA {dataSelected.nombre_plato}
          </Typography>
          <Button
            autoFocus
            variant="contained"
            color="inherit"
            onClick={() => handleAddProduct()}
            className="!text-black"
          >
            Agregar
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ padding: 10 }}>
        <TableInsumos />
      </div>
    </Dialog>
  );
};

export default DialogProductos;
