import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";
import { getUser } from "./auth.services";

const { baseurl } = config;

const URI = `${baseurl}/api/supplier`;

export const get_supplier = () => {
  const url = `${URI}/get/`;

  const USER_DATA = getUser();

  return axios
    .get(
      url,
      axiosHeader({
        id_emp: USER_DATA.empresa.id_emp,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_supplier = (params) => {
  const url = `${URI}/add/`;

  const USER_DATA = getUser();

  return axios
    .post(
      url,
      params,
      axiosHeader({
        id_emp: USER_DATA.empresa.id_emp,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_supplier = (params, id) => {
  const url = `${URI}/update/${id}/`;

  const USER_DATA = getUser();

  return axios
    .put(
      url,
      params,
      axiosHeader({
        id_emp: USER_DATA.empresa.id_emp,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const delete_supplier = (id) => {
  const url = `${URI}/delete/${id}/`;

  const USER_DATA = getUser();

  return axios
    .delete(
      url,
      axiosHeader({
        id_emp: USER_DATA.empresa.id_emp,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};