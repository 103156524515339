import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";
import { findPlanningByCod } from "../../services/planning.services";
import Ceremony from "./ceremony.planning";
import EventMenu from "./eventMenu.planning";
import GeneralsData from "./generalsData.planning";
import PartySupplies from "./partySupplies.planning";
import BreadCrumb from "./ui/breadCrumb.ui";
import { getUser } from "../../services/auth.services";

export const initalValuesPlanning = {
  idPlanning: 0,
  usuId: 0,
  idQuotation: 0,
  idEvent: 0,
  idTypeEvent: 0,
  nameBoyfriendQuotation: "",
  nameGirlfriendQuotation: "",
  valuePerPerson: 0,
  minBasePAXPerPerson: 0,
  photographer: "",
  comment: "",
  nameLocalEvent: "",
  ceremonyTime: "",
  eventHoursQuotation: 0,
  nameOfChildren: "",
  nameTypeEvent: "",
  nameEvent: "",
  nameFatherOfBoyFriend: "",
  nameMotherOfBoyFriend: "",
  nameFatherOfGirlFriend: "",
  nameMotherOfGirlFriend: "",
};

const PlanningPageCod = () => {
  const { cod } = useParams();

  const [dataPlanning, setDataPlanning] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  const GESTIONADOR_EVENTOS_ID = 19;
  const ENCARGADO_FINANZAS_ID = 20;

  const isReadMode =
    getUser()?.id_perfil === GESTIONADOR_EVENTOS_ID ||
    getUser()?.id_perfil === ENCARGADO_FINANZAS_ID;

  const canCheck = getUser()?.id_perfil === GESTIONADOR_EVENTOS_ID;

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchDataPlanning = async () => {
    try {
      setLoad(true);
      const data = await findPlanningByCod(cod);

      setLoad(false);

      setDataPlanning(data);
    } catch (error) {
      console.error("Error en fetchDataPlanning: ", error);
    }
  };

  useEffect(() => {
    fetchDataPlanning();
  }, []);

  return (
    <Template title={cod} style={{ position: "relative" }}>
      <div className="p-5">
        <div className="pb-5">
          <BreadCrumb nameCod={cod} />
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Datos generales" value={1} wrapped />
                <Tab label="Menú del evento" value={2} />
                <Tab label="Insumo fiesta" value={3} />
                <Tab label="Ceremonia" value={4} />
              </Tabs>
            </Box>

            {value === 1 && (
              <ContainerInput className="mt-4 p-4">
                <GeneralsData data={dataPlanning} isReadMode={isReadMode} />
              </ContainerInput>
            )}
            {value === 2 && (
              <div className="mt-4">
                <EventMenu data={dataPlanning} isReadMode={isReadMode} />
              </div>
            )}
            {value === 3 && (
              <div className="mt-4">
                <PartySupplies data={dataPlanning} isReadMode={isReadMode} />
              </div>
            )}
            {value === 4 && (
              <div className="mt-4">
                <Ceremony
                  data={dataPlanning}
                  isReadMode={isReadMode}
                  canCheck={canCheck}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Template>
  );
};

export default PlanningPageCod;
