/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import { get_subcategoria } from "../services/categoria.services";

const useSubCategoriaProducto = () => {
  const [dataSubCategoriaProducto, setDataSubCategoriaProducto] =
    React.useState({
      data: [],
      load: false,
      err: false,
    });

  React.useEffect(() => {
    async function obtainDataSubCategoriaProducto() {
      try {
        setDataSubCategoriaProducto({
          ...dataSubCategoriaProducto,
          load: true,
          data: [],
          err: false,
        });

        let response = await get_subcategoria();

        setDataSubCategoriaProducto({
          ...dataSubCategoriaProducto,
          load: false,
          data: response.map((el) => ({
            value: el.id_subcategoria,
            label: el.name_subcategoria,
          })),
          err: false,
        });
      } catch (err) {
        setDataSubCategoriaProducto({
          ...dataSubCategoriaProducto,
          load: false,
          data: [],
          err: true,
        });
      }
    }

    obtainDataSubCategoriaProducto();

    return null;
  }, []);

  return {
    dataSubCategoriaProducto,
  };
};

export default useSubCategoriaProducto;
