import React from "react";
import { DateRangePicker } from "rsuite";

import "rsuite/DateRangePicker/styles/index.css";
import "./styleDateRangePicker.scss";

const BasicDateRangePicker = ({ value = [], onChange = () => {} }) => {
  return (
    <DateRangePicker
      format="dd/MM/yyyy"
      character=" – "
      showHeader={false}
      value={value}
      onChange={onChange}
      showMeridian
      preventOverflow
      size="lg"
      onClean={() => onChange([])}
    />
  );
};

export default BasicDateRangePicker;
