import { Article, Check } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Template from "../../components/Template";
import { formatMoney } from "../../functions/formatMoney";
import {
  findPlanningByCod,
  getFeePayment,
} from "../../services/planning.services";
import BreadCrumb from "./ui/breadCrumb.ui";
import DynamicTable from "./ui/dynamicTable";
import DialogFeePayment from "./dialogFeePayment";
import { config } from "../../config";

const RenderFinancePlanningId = () => {
  const { cod } = useParams();

  const [load, setLoad] = React.useState(false);
  const [dataPlanning, setDataPlanning] = React.useState(null);

  const [dataFeePayment, setDataFeePayment] = React.useState([]);

  const [feePaymentSelected, setFeePaymentSelected] = React.useState(null);

  const fetchDataPlanning = async () => {
    try {
      setLoad(true);
      const data = await findPlanningByCod(cod);

      setLoad(false);

      setDataPlanning(data);
    } catch (error) {
      console.error("Error en fetchDataPlanning: ", error);
    }
  };

  const handleSelected = (id) => {
    const feePaymentSelected = dataFeePayment.find(
      (item) => item.id_feepayment === id
    );

    setFeePaymentSelected(feePaymentSelected);
  };

  useEffect(() => {
    async function handleGetData() {
      try {
        setLoad(true);

        let response = await getFeePayment(dataPlanning?.id_planning);

        response = response.map((el) => ({
          ...el,
          date_feepayment: dayjs(el.date_feepayment).format("DD/MM/YYYY"),
          value_feepayment: formatMoney(el.value_feepayment),
        }));

        setLoad(false);

        setDataFeePayment(response);
      } catch (err) {
        setLoad(false);
      }
    }

    if (!dataPlanning) return;

    handleGetData();
  }, [dataPlanning]);

  useEffect(() => {
    fetchDataPlanning();
  }, []);

  return (
    <Template title={cod} style={{ position: "relative" }}>
      <div className="p-5">
        <div className="pb-5">
          <BreadCrumb nameCod={cod} />
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <DynamicTable
              isReadMode={true}
              columns={[
                {
                  id: "date_feepayment",
                  label: "Fecha a pagar",
                  minWidth: 200,
                  align: "left",
                  input: "date",
                },
                {
                  id: "value_feepayment",
                  label: "Valor a pagar",
                  minWidth: 200,
                  align: "left",
                  input: "text",
                },
                {
                  id: "paymentdate_feepayment",
                  label: "Fecha pagada",
                  minWidth: 200,
                  align: "left",
                  input: "datetime-local",
                  format: (item) => {
                    return item && dayjs(item).format("DD/MM/YYYY HH:mm");
                  },
                },
                {
                  id: "valuepay_feepayment",
                  label: "Valor pagado",
                  minWidth: 200,
                  align: "left",
                  input: "number",
                  format: (item) => {
                    return item && formatMoney(item);
                  },
                },
                {
                  id: "filename_feepayment",
                  label: "Contrato",
                  minWidth: 50,
                  align: "center",
                  format: (item) => {
                    if (!item)
                      return (
                        <div className={`text-gray-400 cursor-auto`}>
                          <Article />
                        </div>
                      );

                    const url = `${config.doUrlSpace}/planning/finance/${item}`;

                    return (
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`text-blue-500`}
                      >
                        <Article />
                      </a>
                    );
                  },
                },
                {
                  id: "id_feepayment",
                  label: "Pagar",
                  minWidth: 50,
                  align: "center",
                  format: (item) => {
                    return (
                      <IconButton
                        color="inherit"
                        onClick={() => handleSelected(item)}
                        className={`!bg-slate-500`}
                      >
                        <Check />
                      </IconButton>
                    );
                  },
                },
              ]}
              rows={dataFeePayment}
              setRows={($rows) => setDataFeePayment($rows)}
              addRow={true}
              load={load}
            />
          </div>
        </div>
      </div>

      <DialogFeePayment
        open={feePaymentSelected !== null}
        setOpen={setFeePaymentSelected}
        selected={feePaymentSelected}
        callback={fetchDataPlanning}
      />
    </Template>
  );
};

export default RenderFinancePlanningId;
