import { Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";

const FilterSupplier = ({ onSearchData, onOpenAddModal, onLoadData }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchName: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSearchData)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-4 flex gap-2">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"text"}
                title={"Buscar por nombres, RUT, email o contacto"}
              />
            )}
          />
          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
          >
            <Search />
          </IconButton>
        </div>
      </div>
      <div className="col-span-12 md:col-span-4 flex justify-end mb-4">
        <Button
          variant="contained"
          color="secondary"
          className="ml-auto w-full md:w-auto"
          onClick={() => onOpenAddModal(true)}
        >
          Crear nuevo
        </Button>
      </div>
    </form>
  );
};

export default FilterSupplier;
