import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import { Search } from "@mui/icons-material";

import { Controller, useForm } from "react-hook-form";
import Input from "../../../../../../components/Forms/Input";

// import useCategoriaProducto from "../../../../../../hooks/useCategoriaProducto";
// import useMarca from "../../../../../../hooks/useMarca";
import { AddCircle, Delete } from "@mui/icons-material";
import {
  get_plato,
  search_plato,
} from "../../../../../../services/plato.services";

import { useDispatch, useSelector } from "react-redux";
import NotFound from "../../../../../../atomic/organisms/NotFound.organisms";
import { formatMoney } from "../../../../../../functions/formatMoney";
import { actionTypes } from "../../../../../../redux/menu/actions";

const columns = [
  {
    id: "id_plato",
    label: "Código",
    minWidth: 30,
    align: "left",
    price: false,
  },
  {
    id: "nombre_plato",
    label: "Nombre",
    minWidth: 80,
    align: "left",
    price: false,
  },
  {
    id: "descripcion_plato",
    label: "Descripción",
    minWidth: 120,
    align: "left",
    price: false,
  },
  {
    id: "precio_plato",
    label: "Precio",
    minWidth: 80,
    align: "right",
    price: true,
  },
];

/*
  TABLE LIST PRODUCT
*/

const TableHeadComponent = () => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, idx) => (
          <TableCell
            key={idx}
            align={column.align}
            style={{ minWidth: column.minWidth }}
          >
            {column.label}
          </TableCell>
        ))}
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
};

const TableBodyComponent = (data) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let { dataPlato, dataSelected, handleSelectedRow } = data;

  const handleAddTmp = (param) => {
    handleSelectedRow(param);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setPage(0);
  }, []);

  if (dataPlato.load) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (dataPlato.err) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <NotFound title="¡Ocurrió un problema!" />
      </div>
    );
  }

  if (dataPlato.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  const isSelected = (name) => {
    return dataSelected.find((item) => item.id_plato === name);
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHeadComponent />

          <TableBody>
            {dataPlato.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                const isItemSelected = isSelected(row.id_plato);
                // const labelId = `table-td-${idx}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    {columns.map((column, idx) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={idx} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : column.price
                            ? formatMoney(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell padding="checkbox">
                      <IconButton
                        disabled={isItemSelected ? true : false}
                        color="success"
                        size="large"
                        onClick={() => handleAddTmp(row)}
                      >
                        <AddCircle />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todo" }]}
        component="div"
        count={dataPlato.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

/*
  TMP TABLE
*/

const TableTmpAdd = (props) => {
  let { data, numSelected, handleRemoveRow } = props;

  if (numSelected === 0) {
    return false;
  }

  let rows = data;

  return (
    <>
      <Paper variant="outlined" style={{ padding: 10, background: "#f7f7f7" }}>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle1" component="div">
            Platos / bebidas seleccionadas: {numSelected}
          </Typography>
        )}
      </Paper>
      <Paper
        variant="outlined"
        style={{ background: "#f7f7f7", marginTop: 10 }}
      >
        <TableContainer sx={{ maxHeight: 640 }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align={"left"} style={{ minWidth: 150 }}>
                  Código
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 150 }}>
                  Nombre
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 150 }}>
                  Descripción
                </TableCell>
                <TableCell align={"left"} style={{ minWidth: 150 }}>
                  Precio
                </TableCell>
                <TableCell align={"left"}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>{row.id_plato}</TableCell>
                  <TableCell align={"left"}>{row.nombre_plato}</TableCell>
                  <TableCell align={"left"}>{row.descripcion_plato}</TableCell>
                  <TableCell align={"left"}>
                    {formatMoney(row.precio_plato)}
                  </TableCell>
                  <TableCell align={"left"}>
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveRow(row)}
                      size={"small"}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

const TablePlatos = () => {
  const dispatch = useDispatch();

  const { paramSelected_plato } = useSelector((state) => state.reducer_menu);

  const [dataPlato, setDataPlatos] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      text_plato: "",
    },
  });

  const onSetParamSelected = (data) =>
    dispatch({
      type: actionTypes.setParamSelected_plato,
      payload: data,
    });

  const handleSearchPlato = async (data) => {
    try {
      setDataPlatos({ ...dataPlato, load: true, data: [], err: false });
      let response = await search_plato(data);

      setDataPlatos({
        ...dataPlato,
        load: false,
        data: response,
        err: false,
      });
    } catch (err) {
      setDataPlatos({ ...dataPlato, load: false, data: [], err: true });
      console.log(err);
    }
  };

  const handleSelectedRow = (data) => {
    onSetParamSelected([...paramSelected_plato, { ...data }]);
  };

  const handleRemoveRow = (data) => {
    const newData = paramSelected_plato.filter(
      (item) => item.id_plato !== data.id_plato
    );
    onSetParamSelected(newData);
  };

  React.useEffect(() => {
    async function obtainDataPlato() {
      try {
        setDataPlatos({ ...dataPlato, load: true, data: [], err: false });
        let response = await get_plato();

        console.log("obtainDataPlato", response);
        
        setDataPlatos({
          ...dataPlato,
          load: false,
          data: response,
          err: false,
        });
      } catch (err) {
        setDataPlatos({ ...dataPlato, load: false, data: [], err: true });
      }
    }

    obtainDataPlato();

    return null;
  }, []);

  return (
    <>
      <Paper variant="outlined" style={{ padding: 10 }}>
        <form onSubmit={handleSubmit(handleSearchPlato)} autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Controller
                name="text_plato"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    fullWidth
                    value={value}
                    onChange={onChange}
                    type="text"
                    title="Nombre plato"
                  />
                )}
              />
            </Grid>

            <Grid item xs={1} style={{ textAlign: "center" }}>
              <IconButton
                type="submit"
                size="large"
                color="primary"
                style={{ background: "#eee" }}
              >
                <Search />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <TableBodyComponent
        dataPlato={dataPlato}
        dataSelected={paramSelected_plato}
        handleSelectedRow={handleSelectedRow}
      />

      <TableTmpAdd
        numSelected={paramSelected_plato.length}
        data={paramSelected_plato}
        handleRemoveRow={handleRemoveRow}
      />
    </>
  );
};

export default TablePlatos;
