import { useEffect, useState } from "react";

import { Save } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ContainerInput from "../../components/ContainerInput";
import {
  addPlaningManteleria,
  addPlaningTortas,
  addPlaningTrasNoche,
  getPlaningManteleria,
  getPlaningTortas,
  getPlaningTrasNoche,
} from "../../services/planning.services";
import DynamicTable from "./ui/dynamicTable";
import InputDefault from "../../atomic/atoms/input.atoms";
import toast from "react-hot-toast";

import { s3Client } from "../../libs/s3Client";
import { v4 as uuidv4 } from "uuid";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { config } from "../../config";

const MenuComponent = ({ title, children, ...props }) => {
  return (
    <>
      <p
        className={`text-sm font-semibold mb-2 bg-slate-400 p-2 text-white ${props.className}`}
      >
        {title}
      </p>
      <ContainerInput className="p-4">{children}</ContainerInput>
    </>
  );
};

const LoadingComponent = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <CircularProgress style={{ color: "#e0004d", marginBottom: 5 }} />
      <div style={{ fontSize: "18px", color: "#e0004d" }}>Cargando...</div>
    </div>
  );
};

const TrasNoche = ({ planningId, isReadMode }) => {
  const [load, setLoad] = useState(false);

  const [dataTrasNoche, setDataTrasNoche] = useState({
    planningId,
    menuDetail: [],
  });

  const handleSubmit = async () => {
    try {
      setLoad(true);
      await addPlaningTrasNoche(dataTrasNoche);
      setLoad(false);
    } catch (err) {
      setLoad(false);
    }
  };

  const handleFetchData = async () => {
    setLoad(true);

    try {
      let response = await getPlaningTrasNoche(planningId);

      // set load
      setLoad(false);

      // format data
      response = response.map((el) => ({
        nameTrasnoche: el.name_trasnoche,
        typeTrasnoche: el.type_trasnoche,
      }));

      // set data
      setDataTrasNoche({
        ...dataTrasNoche,
        menuDetail: response,
      });
    } catch (err) {
      setLoad(false);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  if (load) {
    return <LoadingComponent />;
  }

  return (
    <MenuComponent title="Tras noche">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <DynamicTable
            isReadMode={isReadMode}
            columns={[
              {
                id: "delete",
                label: "Eliminar",
                input: "delete",
                minWidth: 10,
                align: "left",
              },
              {
                id: "typeTrasnoche",
                label: "Tipo",
                minWidth: 400,
                align: "left",
                input: "select",
                options: [
                  { value: "cafe", label: "ESTACIÓN CAFE" },
                  { value: "dulce", label: "ESTACIÓN DULCE" },
                  { vañue: "snack", label: "SNACK TRASNOCHE" },
                  { value: "postre", label: "POSTRES ADICIONALES" },
                ],
              },

              {
                id: "nameTrasnoche",
                label: "Descripción",
                minWidth: 400,
                align: "left",
                input: "text",
              },
            ]}
            rows={dataTrasNoche.menuDetail}
            setRows={($rows) =>
              setDataTrasNoche({ ...dataTrasNoche, menuDetail: $rows })
            }
            addRow={true}
          />
        </Grid>

        {!isReadMode && (
          <Grid item xs={12} sm={12}>
            <Button
              onClick={handleSubmit}
              className="text-right"
              variant="contained"
              size="small"
              color="secondary"
              type="button"
            >
              <Save /> Guardar
            </Button>
          </Grid>
        )}
      </Grid>
    </MenuComponent>
  );
};

const Tortas = ({ planningId, isReadMode }) => {
  const [load, setLoad] = useState(false);

  const [dataTortas, setDataTortas] = useState({
    idPlTorta: null,
    idPlanning: planningId,
    detailPlTorta: "",
    filenamePlTorta: null,
    observationsPlTorta: "",
    inputFileTorta: null,
  });

  const handleUpload = async (inputFile) => {
    if (!inputFile) {
      toast.error("Debe de subir el contrato de cotización");

      return;
    }

    const file = inputFile[0];
    const fileExtension = file.name.split(".").pop();
    const fileName = `${uuidv4()}.${fileExtension}`;

    const bucketParams = {
      Bucket: "ubuntu-storage",
      Key: `planning/tortas/${fileName}`,
      Body: file,
      ACL: "public-read",
    };

    try {
      await s3Client.send(new PutObjectCommand(bucketParams));
      return fileName;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoad(true);

      let fileName;

      // check if file is empty
      if (dataTortas?.inputFileTorta && dataTortas?.inputFileTorta !== "") {
        fileName = await handleUpload(dataTortas.inputFileTorta);
      }

      // add tortas
      const response = await addPlaningTortas({
        ...dataTortas,
        filenamePlTorta: fileName,
      });

      // set data tortas
      setDataTortas({
        ...dataTortas,
        filenamePlTorta: response[0]?.filename_pltorta || "",
      });

      setLoad(false);
    } catch (err) {
      setLoad(false);
    }
  };

  const handleFetchData = async () => {
    setLoad(true);

    try {
      let response = await getPlaningTortas(planningId);

      // set load
      setLoad(false);

      response = response[0];

      // format data
      response = {
        idPlTorta: response?.id_pltorta,
        detailPlTorta: response?.detail_pltorta,
        filenamePlTorta: response?.filename_pltorta,
        observationsPlTorta: response?.observations_pltorta,
      };

      // set data
      setDataTortas({
        ...dataTortas,
        ...response,
      });
    } catch (err) {
      setLoad(false);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  if (load) {
    return <LoadingComponent />;
  }

  return (
    <MenuComponent title="Tortas" className="mt-4">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Table size="small" aria-label="simple table" className="border-2">
            <TableHead>
              <TableRow className="bg-slate-50">
                <TableCell className="!font-bold !text-xs">
                  Sabor por piso
                </TableCell>
                <TableCell className="!font-bold !text-xs">Foto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="w-[50%]">
                  <InputDefault
                    isReadMode={isReadMode}
                    title="Detalles"
                    name="detailPlTorta"
                    type={"textarea"}
                    minRows={4}
                    value={dataTortas.detailPlTorta}
                    onChange={(e) =>
                      setDataTortas({
                        ...dataTortas,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell className="w-[50%]">
                  {dataTortas.filenamePlTorta && (
                    <img
                      src={`${config.doUrlSpace}/planning/tortas/${dataTortas.filenamePlTorta}`}
                      alt="torta"
                      className="w-80 h-auto text-center m-auto mb-4"
                    />
                  )}
                  {!isReadMode && (
                    <input
                      name="inputFileTorta"
                      type={"file"}
                      accept="image/*"
                      onChange={(e) =>
                        setDataTortas({
                          ...dataTortas,
                          [e.target.name]: e.target.files,
                        })
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputDefault
            isReadMode={isReadMode}
            title="Observaciones"
            name="observationsPlTorta"
            type={"textarea"}
            minRows={2}
            value={dataTortas.observationsPlTorta}
            onChange={(e) =>
              setDataTortas({
                ...dataTortas,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        {!isReadMode && (
          <Grid item xs={12} sm={12}>
            <Button
              onClick={handleSubmit}
              className="text-right"
              variant="contained"
              color="secondary"
              type="button"
              size="small"
            >
              <Save /> Guardar
            </Button>
          </Grid>
        )}
      </Grid>
    </MenuComponent>
  );
};

const Manteleria = ({ planningId, isReadMode }) => {
  const [load, setLoad] = useState(false);

  const [dataManteleria, setDataManteleria] = useState({
    idPlManteleria: null,
    idPlanning: planningId,
    namePlManteleria: "",
    folderorpathPlManteleria: "",
    groomsskirtPlManteleria: "",
    observationsPlManteleria: "",
    individualsPlManteleria: "",
    filenamePlManteleria: "",
    slatsPlManteleria: "",
    napkinsPlManteleria: "",
    additionalDetailsPlManteleria: "",
    inputFileManteleria: null,
  });

  const handleUpload = async (inputFile) => {
    if (!inputFile) {
      toast.error("Debe de subir el contrato de cotización");

      return;
    }

    const file = inputFile[0];
    const fileExtension = file.name.split(".").pop();
    const fileName = `${uuidv4()}.${fileExtension}`;

    const bucketParams = {
      Bucket: "ubuntu-storage",
      Key: `planning/manteleria/${fileName}`,
      Body: file,
      ACL: "public-read",
    };

    try {
      await s3Client.send(new PutObjectCommand(bucketParams));
      return fileName;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoad(true);

      let fileName;

      // check if file is empty
      if (
        dataManteleria?.inputFileManteleria &&
        dataManteleria?.inputFileManteleria !== ""
      ) {
        fileName = await handleUpload(dataManteleria.inputFileManteleria);
      }

      // add manteleria
      const response = await addPlaningManteleria({
        ...dataManteleria,
        filenamePlManteleria: fileName,
      });

      // set data manteleria
      setDataManteleria({
        ...dataManteleria,
        filenamePlManteleria: response[0]?.filename_manteleria || "",
      });

      setLoad(false);
    } catch (err) {
      setLoad(false);
    }
  };

  const handleFetchData = async () => {
    setLoad(true);

    try {
      let response = await getPlaningManteleria(planningId);
      response = response[0];

      // format data
      response = {
        idPlManteleria: response?.id_manteleria,
        namePlManteleria: response?.name_manteleria,
        folderorpathPlManteleria: response?.folderorpath_manteleria,
        groomsskirtPlManteleria: response?.groomsskirt_manteleria,
        observationsPlManteleria: response?.observations_manteleria,
        individualsPlManteleria: response?.individuals_manteleria,
        filenamePlManteleria: response?.filename_manteleria,
        slatsPlManteleria: response?.slats_manteleria,
        napkinsPlManteleria: response?.napkins_manteleria,
        additionalDetailsPlManteleria: response?.additional_details_manteleria,
      };

      // set data
      setDataManteleria({
        ...dataManteleria,
        ...response,
      });

      setLoad(false);
    } catch (err) {
      setLoad(false);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  if (load) {
    return <LoadingComponent />;
  }

  return (
    <MenuComponent title="Manteleria" className="mt-4">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Mantel"
            name="namePlManteleria"
            type={"text"}
            value={dataManteleria.namePlManteleria}
            onChange={(e) =>
              setDataManteleria({
                ...dataManteleria,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Carpeta o camino"
            name="folderorpathPlManteleria"
            type={"text"}
            value={dataManteleria.folderorpathPlManteleria}
            onChange={(e) =>
              setDataManteleria({
                ...dataManteleria,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Faldon novios"
            name="groomsskirtPlManteleria"
            type={"text"}
            value={dataManteleria.groomsskirtPlManteleria}
            onChange={(e) =>
              setDataManteleria({
                ...dataManteleria,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Listones"
            name="slatsPlManteleria"
            type={"text"}
            value={dataManteleria.slatsPlManteleria}
            onChange={(e) =>
              setDataManteleria({
                ...dataManteleria,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Individuales"
            name="individualsPlManteleria"
            type={"text"}
            value={dataManteleria.individualsPlManteleria}
            onChange={(e) =>
              setDataManteleria({
                ...dataManteleria,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Servilletas"
            name="napkinsPlManteleria"
            type={"text"}
            value={dataManteleria.napkinsPlManteleria}
            onChange={(e) =>
              setDataManteleria({
                ...dataManteleria,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputDefault
            isReadMode={isReadMode}
            title="Observaciones"
            name="observationsPlManteleria"
            type={"textarea"}
            minRows={2}
            value={dataManteleria.observationsPlManteleria}
            onChange={(e) =>
              setDataManteleria({
                ...dataManteleria,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {dataManteleria.filenamePlManteleria && (
            <img
              src={`${config.doUrlSpace}/planning/manteleria/${dataManteleria.filenamePlManteleria}`}
              alt="torta"
              className="w-80 h-auto text-center m-auto mb-4"
            />
          )}
          {!isReadMode && (
            <input
              name="inputFileManteleria"
              type={"file"}
              accept="image/*"
              onChange={(e) =>
                setDataManteleria({
                  ...dataManteleria,
                  [e.target.name]: e.target.files,
                })
              }
            />
          )}
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <p className="text-sm font-semibold">Opcional</p>
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputDefault
            isReadMode={isReadMode}
            title="Decoraciones adicionales"
            name="additionalDetailsPlManteleria"
            type={"textarea"}
            minRows={3}
            value={dataManteleria.additionalDetailsPlManteleria}
            onChange={(e) =>
              setDataManteleria({
                ...dataManteleria,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Grid> */}
        {!isReadMode && (
          <Grid item xs={12} sm={12}>
            <Button
              onClick={handleSubmit}
              className="text-right"
              variant="contained"
              color="secondary"
              type="button"
              size="small"
            >
              <Save /> Guardar
            </Button>
          </Grid>
        )}
      </Grid>
    </MenuComponent>
  );
};

const AdditionalDecorations = ({ planningId, isReadMode }) => {
  const [load, setLoad] = useState(false);

  if (load) {
    return <LoadingComponent />;
  }

  return <MenuComponent title="Decoraciones adicionales" className="mt-4" />;
};

const PartySupplies = ({ data, isReadMode }) => {
  const idPlanning = data.id_planning;

  return (
    <>
      <TrasNoche planningId={idPlanning} isReadMode={isReadMode} />
      <Tortas planningId={idPlanning} isReadMode={isReadMode} />
      <Manteleria planningId={idPlanning} isReadMode={isReadMode} />
      <AdditionalDecorations planningId={idPlanning} isReadMode={isReadMode} />
    </>
  );
};

export default PartySupplies;
