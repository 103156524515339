/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Template from "../../components/Template";

import { checkToken } from "../../services/auth.services";
import { get_categoria } from "../../services/categoria.services";

import ContainerInput from "../../components/ContainerInput";
import DialogCrud from "./Dialog/CrudForm";
import DialogDelete from "./Dialog/ConfirmDelete/index";
// import LoadingPage from "../../components/LoadingPage";

import Table from "./Table/index";

import { Grid, Button } from "@mui/material";

import { Add } from "@mui/icons-material";

import { useSelector, useDispatch } from "react-redux";
import { actionTypes } from "../../redux/categoria/actions";

const Categoria = () => {
  let history = useHistory();

  const dispatch = useDispatch();

  const { dataCategoria, loading } = useSelector(
    (state) => state.reducer_categoria
  );

  const onOpen = (data) =>
    dispatch({
      type: actionTypes.updateDialogEditCategoria,
      payload: data,
    });

  const onGet = (data) =>
    dispatch({
      type: actionTypes.getDataCategoria,
      payload: data,
    });

  useEffect(() => {
    async function obtainDataCategoria() {
      try {
        onGet({ ...dataCategoria, load: true, data: [], err: false });
        let response = await get_categoria();

        onGet({ ...dataCategoria, load: false, data: response, err: false });
      } catch (err) {
        console.log(err);
        onGet({ ...dataCategoria, load: false, data: [], err: true });
      }
    }

    if (!loading) {
      obtainDataCategoria();
    }

    return null;
  }, [loading]);

  useEffect(() => {
    if (!checkToken()) {
      history.push("/");
      return null;
    }
  }, []);

  return (
    <Template title="Categoría">
      {/* <LoadingPage open={loading} /> */}
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={11}
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={1}
          lg={1}
          style={{
            textAlign: "right",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              onOpen(true);
            }}
            className="button-default"
          >
            <Add />
          </Button>
        </Grid>
      </Grid>

      <ContainerInput className="!p-0">
        <Table />
      </ContainerInput>
      <DialogCrud />
      <DialogDelete />
    </Template>
  );
};

export default Categoria;
