import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputDefault from "../../../atomic/atoms/input.atoms";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import ContainerInput from "../../../components/ContainerInput";
import { getPlanning } from "../../../services/planning.services";
import BasicDateRangePicker from "../../quotation/ui/BasicDateRangePicker.quotation";

const FilterPlanning = ({ onSearchData, onLoadData }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      searchName: "",
      dateRange: [],
    },
  });

  return (
    <form onSubmit={handleSubmit(onSearchData)} autoComplete="off">
      <div className="mb-4 w-full grid grid-cols-12 gap-2">
        <div className="col-span-4">
          <Controller
            name="searchName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputDefault
                value={value}
                onChange={onChange}
                type={"text"}
                title={"Buscar por código..."}
              />
            )}
          />
        </div>
        <div className="col-span-6 md:col-span-3 flex gap-2">
          <Controller
            name="dateRange"
            className="!w-full"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <BasicDateRangePicker value={value} onChange={onChange} />
            )}
          />
          <IconButton
            type="submit"
            color="default"
            className="ml-auto col-span-1"
            disabled={onLoadData}
          >
            <Search />
          </IconButton>
        </div>
      </div>
    </form>
  );
};

const ChooseEventsPurchase = ({ seletedEvent, onSelectEvent }) => {
  const [dataPlanning, setDataPlanning] = useState([]);
  const [load, setLoad] = useState(false);

  const columns = [
    {
      id: "cod_planning",
      label: "Código",
      minWidth: 80,
      align: "left",
    },
    {
      id: "name_event",
      label: "Evento",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "name_typeevent",
      label: "Tipo de evento",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "usu_nombre",
      label: "Usuario",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "created_at_planning",
      label: "Fecha creación",
      minWidth: 160,
      align: "left",
      format: (el) => {
        return dayjs(el).utc().format("DD/MM/YYYY");
      },
    },
  ];

  const handleSearchData = async (value) => {
    setLoad(true);

    let response = await getPlanning();

    response = response.map((el) => ({
      ...el,
      selected: false,
      canBeSelectable: true,
    }));

    if (response.length === 0) {
      return;
    }

    let filterData = response;

    if (value?.searchName) {
      filterData = filterData.filter((el) => {
        return (
          el.name_event
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.cod_planning.toLowerCase().includes(value.searchName.toLowerCase())
        );
      });
    }

    if (value?.dateRange.length > 0) {
      filterData = filterData.filter((el) => {
        const date = dayjs(el.created_at_planning).utc();

        return (
          date.isAfter(dayjs(value.dateRange[0]).utc()) &&
          date.isBefore(dayjs(value.dateRange[1]).utc())
        );
      });
    }

    setDataPlanning(filterData);

    setLoad(false);
  };

  const fetchDataPlanning = async () => {
    try {
      setLoad(true);
      let data = await getPlanning();

      data = data.map((el) => {
        const selected = seletedEvent.some(
          (event) => event.id_planning === el.id_planning
        );

        return {
          ...el,
          selected: selected,
          canBeSelectable: true,
        };
      });

      setLoad(false);

      setDataPlanning(data);
    } catch (error) {
      console.error("Error en fetchDataPlanning: ", error);
    }
  };

  const onRowSelected = (row) => {
    setDataPlanning((prev) => {
      const newSelected = prev.map((el) => {
        if (el.id_planning === row.id_planning) {
          return {
            ...el,
            selected: !el.selected,
          };
        }

        return el;
      });

      onSelectEvent(
        newSelected.map((el) => ({
          id_planning: el.id_planning,
          selected: el.selected,
          cod_planning: el.cod_planning,
          name_event: el.name_event,
          name_typeevent: el.name_typeevent,
          usu_nombre: el.usu_nombre,
          created_at_planning: el.created_at_planning,
        }))
      );

      return newSelected;
    });
  };

  useEffect(() => {
    fetchDataPlanning();
  }, []);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-12">
        {/* <FilterPlanning onSearchData={handleSearchData} onLoadData={load} /> */}
        <ContainerInput className="p-0">
          <CustomTable
            onRowSelected={onRowSelected}
            columns={columns}
            rows={dataPlanning}
            // selectedRows={selectedPlanning}
            load={load}
            error={false}
            pagination={true}
          />
        </ContainerInput>
      </div>
    </div>
  );
};

export default ChooseEventsPurchase;
